import axios from 'axios';

const utf8_to_b64 = (str) => {
  return window.btoa(unescape(encodeURIComponent(str)));
}

const b64_to_utf8 = (str) => {
  try {
    return window.atob(decodeURIComponent(str))
    // return decodeURIComponent(escape(window.atob(str)));
  } catch(err) {
    console.log(err);
    return str;
  }
}

const token = b64_to_utf8(window.location.href.split('?uid=')[1]);
const vmId = '63a41d00b098b08886e2663b';
const cId = '63a41cd7b098b08886e26639';
const baseUri = 'https://api.agrid.io';

export const getData = () => {
  return new Promise(function(resolve, reject) {
    const uri = baseUri + '/virtual-machine/' + vmId;
    return axios.get(uri, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
};

export const listData = () => {
  return new Promise(function(resolve, reject) {
    const uri = baseUri + '/list-data/' + cId;
    return axios.get(uri, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
};

export const saveData = (sources, keywords, blacklist) => {
  return new Promise(function(resolve, reject) {
    const uri = baseUri + '/configure/' + cId;
    return axios.post(uri, {
      sources: sources,
      keywords: keywords,
      blacklist: blacklist
    }, {
      headers: { Authorization: `Bearer ${token}` }
    }).then(res => {
      resolve(res.data);
    }).catch(err => {
      reject(err);
    });
  });
};
