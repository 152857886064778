import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import LinkIcon from '@mui/icons-material/Link';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#5a13ff',
    color: theme.palette.common.white,
    fontSize: 15,
    textAlign: 'Left',
    fontFamily: 'Montserrat',
    fontWeight: 'bold'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    textAlign: 'Left',
    fontFamily: 'Montserrat'
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
    textAlign: 'Left'
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    textAlign: 'Left'
  },
}));

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
  createData('Eclair', 262, 16.0, 24, 6.0),
  createData('Cupcake', 305, 3.7, 67, 4.3),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
  createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CustomizedTables(props) {
  const openText = (msg) => {
    alert(msg)
  }

  const textIsOk = (text) => {
    // return !(text.includes('אני מחפש') || text.includes('אני מחפשת') || text.includes('מחפש עבודה') || text.includes('מחפשת עבודה') || text.includes('מחפשות עבודה') || text.includes('מחפשים עבודה'))
    return true;
  };
  const extractEmails = (text) => {
    try {
      const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
      const emails = text.match(emailRegex);
      return emails[0];
    } catch (err) {
      return '';
    }
  };
  return (
    <>
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Full name</StyledTableCell>
            <StyledTableCell align="right">Messenger</StyledTableCell>
            <StyledTableCell align="right">Phone number</StyledTableCell>
            <StyledTableCell align="right">Email</StyledTableCell>
            <StyledTableCell align="right">Text</StyledTableCell>
            <StyledTableCell align="right">Link</StyledTableCell>
            <StyledTableCell align="right">Created at</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data && props.data.map((row, i) => {
            if (textIsOk(row.rawText) && row.rawText.length >= 50) {
              if (row.extendedData) {
                let o = row.extendedData;
                return (
                  <StyledTableRow key={i.toString()}>
                    <StyledTableCell component="th" scope="row">
                      {o.full_name}
                    </StyledTableCell>
                    <StyledTableCell align="right"><LinkIcon onClick={(e) => window.open(o.messenger_link, '_blank')} style={{color: '#5a13ff', cursor: 'pointer'}}/></StyledTableCell>
                    <StyledTableCell align="right">{o.phone_number !== null ? o.phone_number : '-'}</StyledTableCell>
                    <StyledTableCell align="right">{extractEmails(row.rawText)}</StyledTableCell>
                    <StyledTableCell align="right" style={{cursor: 'pointer'}} onClick={() => openText(row.rawText)}>{row.rawText.slice(0, 105)+'...'}</StyledTableCell>
                    <StyledTableCell align="right"><LinkIcon onClick={(e) => window.open('https://facebook.com/'+o.profile_link, '_blank')} style={{color: '#5a13ff', cursor: 'pointer'}}/></StyledTableCell>
                    <StyledTableCell align="right">{row.createdAt}</StyledTableCell>
                  </StyledTableRow>
                )
              }
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
    <br />
    <br />
    <br />
    <br />
    </>
  );
}
