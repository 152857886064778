import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../../components/Header';
import Table from '../../components/Table';
import SettingsComponent from '../../components/Settings';
import Loader from '../../components/Loader';
import ServerStatus from '../../components/ServerStatus';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import { getData, listData } from '../../api/API';

const Container = styled.div`
`;

const Wrapper = styled.div`
  padding-left: 10%;
  padding-top: 70px;
`;

const MainTitle = styled.h1`
  text-decoration: underline;
`;

const Settings = styled(SettingsIcon)`
  text-decoration: none;
  font-size: 27px !important;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #5a13ff;
  }
`;
const Close = styled(CloseIcon)`
  text-decoration: none;
  font-size: 27px !important;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #5a13ff;
  }
`;
const Sync = styled(SyncIcon)`
  text-decoration: none;
  font-size: 27px !important;
  margin-left: 5px;
  cursor: pointer;
  &:hover {
    color: #5a13ff;
  }
`;
const TableContainer = styled.div`
  width: 90%;
`;
const LastUpdatedAtText = styled.p`
  font-weight: 500;
  margin-bottom: 1px;
`;


function HomePage() {
  const [openSettings, setOpenSettings] = useState(false);
  const [data, setData] = useState([]);
  const [tabledata, settableData] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [sources, setSources] = useState([]);
  const [blacklist, setBlacklist] = useState([]);

  const [error, setError] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const update = () => {
    alert('Data updating..')
    getOpenDB();
  }

  const getOpenDB = () => {
    setIsLoading(true);
    return getData().then(data => {
      data = data.output;
      const machine = data.machine;
      const client = data.client;

      const keywords_ = client.keywords;
      const sources_ = client.sources;
      const blacklist_ = client.blacklist;

      setKeywords(keywords_);
      setSources(sources_);
      setBlacklist(blacklist_);

      return listData().then(data => {
        settableData(data['data'].reverse())
        setData(data['data'].reverse())
        setIsLoading(false);
      }).catch(err => {
        setError(true);
        setIsLoading(false);
      });

    }).catch(err => {
      setError(true);
      setIsLoading(false);
    });
  }

  useEffect(() => {
    if (!dataFetched) {
      getOpenDB();
      setDataFetched(true);
    }
  }, []);

  return (
    <Container>
      <Header />

      <Wrapper>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            {error ? (
              <MainTitle>Error connecting to server..</MainTitle>
            ) : (
              <>
                <ServerStatus />
                <MainTitle>OpenDB funnel {!openSettings ? <Settings onClick={() => setOpenSettings(true)}/> : <Close onClick={() => setOpenSettings(false)}/>}<Sync onClick={() => update()}/></MainTitle>
                {openSettings && (
                  <>
                  <SettingsComponent sources={sources} keywords={keywords} blacklist={blacklist}/>
                  <br />
                  </>
                )}
                <TableContainer>
                  {/*<LastUpdatedAtText><u>Last updated at:</u> <span style={{fontWeight: 'normal'}}>10:45 18/12/2022 (GMT+1;Netherlands)</span></LastUpdatedAtText>*/}
                  <Table data={tabledata}/>
                </TableContainer>
              </>
            )}
          </>
        )}
      </Wrapper>
    </Container>
  );
}

export default HomePage;
